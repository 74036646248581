import { qs } from "../utils/dom"

export default function () {
  const hero = qs('.mainpage-hero')
  // const logo = qs('.header-menu-logo')
  const header = qs('.header')

  const heroRect = hero?.getBoundingClientRect()

  const checkSticky = () => {
    const scrolled = document.documentElement.scrollTop

    if (scrolled > heroRect.height) {
      header.classList.remove('hidden')
      header.classList.add('shown')
    } else {
      header.classList.remove('shown')
      header.classList.add('hidden')
    }
  }

  const checkScrolled = () => {
    const scrolled = document.documentElement.scrollTop
    scrolled > 50
      ? header.classList.add('scrolled')
      : header.classList.remove('scrolled')
  }

  if (hero) {
    checkSticky()
    window.addEventListener('scroll', checkSticky)
    window.addEventListener('resize', checkSticky)
  } else {
    window.addEventListener('scroll', checkScrolled)
    window.addEventListener('resize', checkScrolled)
  }

  const menuBurger = qs('.header-menu-burger')

  if (!menuBurger) return

  const menuWrapper = menuBurger.parentElement
  const itemsList = menuWrapper.querySelector('ul')

  const toggleMenu = () => {
    itemsList.classList.toggle('shown')
    menuBurger.classList.toggle('opened')

    let bodyOverflow

    if (itemsList.matches('.shown')) {
      bodyOverflow = 'hidden'
    } else {
      bodyOverflow = 'unset'
    }

    document.body.style.overflow = bodyOverflow
  }

  menuBurger.addEventListener('click', toggleMenu)
}
