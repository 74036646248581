import { qs } from "../utils/dom";

export default function () {
  const body = qs('body')

  const isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
  const isIOS = /(iPhone|iPod|iPad)/i.test(navigator.platform);

  if (isMacLike || isIOS) body.classList.add('is_safari')
}
