import { qsa, qs } from "../utils/dom"

function calcPawButtonsSize(btn) {
  const spanWidth = qs('span', btn).clientWidth
  const iconWidth = qs('svg', btn).clientWidth
  const btnWidth = btn.clientWidth - iconWidth

  btn.dataset.width = spanWidth + btnWidth + 24
}

export function handlePawButtons() {
  const buttons = qsa('.sk-btn--paw')
  if (!buttons.length) return

  document.addEventListener('click', (e) => {
    const current = e.target

    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      if (!current.classList.contains('active')) {
        current.classList.add('active')
        e.preventDefault()
      }
    }

    if (!current.matches('.sk-btn--paw')) {
      buttons.forEach(btn => {
        btn.style.width = ''
        btn.classList.remove('expanded')
        btn.classList.remove('active')
      })
      return
    }

    calcPawButtonsSize(current)

    if (current.matches('.expanded')) return

    e.preventDefault()
    current.style.width = `${parseFloat(current.dataset.width)}px`
    current.classList.add('expanded')
  })

  document.addEventListener('mouseover', (e) => {
    const current = e.target

    if (!current.matches('.sk-btn--paw')) return

    calcPawButtonsSize(current)

    if (current.matches('.expanded')) return

    if (!current.matches('.expanded')) e.preventDefault()

    console.log(e.target)

    e.target.addEventListener('mouseleave', () => {
      buttons.forEach(btn => {
        btn.style.width = ''
        btn.classList.remove('expanded')
      })
      return
    })

    e.preventDefault()
    current.style.width = `${parseFloat(current.dataset.width)}px`
    current.classList.add('expanded')
  })
}
