import { delay } from "../utils/delays"
import { qs, qsa } from "../utils/dom"

export default function () {
  const biteSound = qs('#bite-sound')
  const biteElements = qsa('[data-aos="bite"]')
  if (!biteSound || !biteElements.length) return

  const observer = new IntersectionObserver(function (entries) {
    const current = entries[0].target
    const playDelay = +current.dataset.aosDelay

    if (entries[0].intersectionRatio <= 0) return
    if (current.matches('.played')) return

    current.classList.add('played')

    delay(() => {
      biteSound.play()
      console.log('played the sound')
    }, playDelay)
  })

  biteElements.forEach(bite => observer.observe(bite))
}
