import AOS from "aos"
import header from "./modules/header"
import checkDevice from "./modules/checkDevice"
import biteAnimation from "./modules/biteAnimation"
import { handlePawButtons } from "./modules/pawButton"

window.addEventListener('load', () => {
  AOS.init({
    once: true,
  })

  handlePawButtons()
  biteAnimation()
  header()
})

document.addEventListener("touchstart", (event) => {

  console.log(event.target)

  const onTouchMove = e => {
    console.log(e.target)
  }
  const onTouchEnd = () => {
      event.target.removeEventListener("touchmove", onTouchMove);
      event.target.removeEventListener("touchend", onTouchEnd);
      // handle touchend here
  }
  event.target.addEventListener("touchmove", onTouchMove);
  event.target.addEventListener("touchend", onTouchEnd);
  // handle touchstart here
});

window.addEventListener('resize', () => {
})

const navbar = document.querySelector('.home .header')

document.addEventListener('DOMContentLoaded', () => {
  checkDevice()
  if (navbar) initNav()
})

function initNav() {
  let lastScrollPos = 0
  let scrollingUp = false

  function handleScroll() {
    const currentScrollPos = window.pageYOffset

    scrollingUp = currentScrollPos < lastScrollPos
    lastScrollPos = currentScrollPos;

    if (!scrollingUp) {
      navbar.style.top = `-300px`
      navbar.style.transition = 'top 0.8s ease-out'
    }
  }

  function handleScrollStop() {
    navbar.style.top = '0'
    navbar.style.transition = 'top 0.5s ease-out'
  }

  window.addEventListener('scroll', handleScroll)
  window.addEventListener('scroll', debounce(handleScrollStop, 50))

  function debounce(func, delay) {
    let timer

    return function() {
      clearTimeout(timer)
      timer = setTimeout(func, delay)
    }
  }
}



